.swiper-images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    overflow-x: auto;
}

.swiper-images>img {
    width: auto;
    height: 44rem;
}

/* Chrome, Edge, and Safari */
.swiper-images::-webkit-scrollbar {
    height: 15px;
}

.swiper-images::-webkit-scrollbar-track {
    background: transparent;
}

.swiper-images::-webkit-scrollbar-thumb {
    background-color: rgba(200, 200, 200, 0.8);
    border-radius: 10px;
}

@media (max-width: 991px) {
    .swiper-images img {
        height: 30rem;
    }
}

@media (max-width: 767px) {
    .swiper-images img {
        height: 20rem;
    }
}

@media (max-width: 576px) {
    .swiper-images img {
        height: 15rem;
    }
}