.navbar {
    background-color: #1D1E3E;
    border-bottom: 0.2px solid gray;
    color: white;
}

.navbar .navbar-logo {
    height: 100%;
    width: 6rem;
    margin-right: 1rem;
}

.navbar-toggler {
    border: none !important;
}

.App {
    background-color: #1D1E3E;
    color: white;
}

.section-introduzione {
    background-color: #1D1E3E;
    padding-top: 3rem;
    margin-bottom: 15rem;
}

.section-introduzione > .container > .container-logo {
    text-align: center;
    margin-block: 11.7rem;
    padding-inline: 3rem;
}

.section-introduzione > .container > .container-logo > img {
    height: 100%;
    width: 100%;
}

.section-introduzione > .container > p:first-of-type {
    margin-bottom: 12rem;
}

.section-introduzione > .container > p {
    text-align: center;
    color: white;
    font-size: 2.25rem;
}

.section-introduzione .card-subtitle {
    font-size: 1.5rem;
}

.section-introduzione .card-title {
    font-size: 2.2rem;
    font-weight: 700;
}

.section-introduzione > .container > img:first-of-type {
    width: 100%;
    height: 100%;
    margin-bottom: 3rem;
}

.section-introduzione > .container > div.row {
    color: white;
}

.poligono {
    position: absolute;
    width: 100%;
    background-color: #1D1E3E;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 1%);
    z-index: -1;
    height: 400px
}

.section-services {
    padding-top: 17rem;
}

.section-services > .container:nth-child(1) {
    margin-bottom: 12rem;
}

.section-services > .container > p {
    margin-bottom: 9rem;
    font-size: 1.5rem;
}

.section-services .container-logo-clienti > div {
    border-block: 1px solid gray;
    padding-block: 5rem;
}

.section-services .container-logo-clienti div.row div.col {
    text-align: center;
}

.section-services .container:nth-child(3) {
    margin-top: 12rem;
}

.section-services .container:nth-child(3) > p:first-of-type {
    margin-bottom: 3.5rem;
    font-size: 2.813rem;
}

.section-services .container:nth-child(3) > p {
    margin-bottom: 17rem;
    font-size: 2rem;
}

footer {
    height: 62rem;
    background-color: white;
    color: #1D1E3E;
    -webkit-mask: url('./images/footer-polygon.svg');
    -webkit-mask-size: 100% 100%;
    -webkit-mask-repeat: no-repeat;
}

footer > div.container > div.row > div[class^="col"] > div {
    width: fit-content;
}

footer > div.container > div.row > div[class^="col"] > div > h1 {
    width: fit-content;
    margin: 0;
    margin-bottom: 1rem;
}

footer > div.container > div.row > div[class^="col"] > div > p {
    width: fit-content;
    margin: 0;
    font-size: 1.5rem;
}

.section-images {
    color: #1D1E3E;
    position: relative;
    isolation: isolate;
}

.section-images h1 {
    margin-bottom: 2rem;
    margin-top: 4rem;
}

.section-images::after {
    content: '';
    z-index: -1;
    inset: 0;
    background-color: white;
    position: absolute;
    transform: skewY(10deg);
}

.selector-lang > label {
    border-color: white;
    border-block: none;
    border-radius: 0px;
    padding-block: 0;
    padding-inline: 1rem;
    color: white;
}

.selector-lang > label:hover {
    color: white !important;
    border-color: white !important;
}

.selector-lang > .btn-check:checked + .btn {
    border-color: white;
    background-color: transparent;
    color: white;
    text-decoration: underline;
}

.selector-lang > label:first-of-type {
    border-left: none;
}

.selector-lang > label:last-of-type {
    border-right: none;
}

.navbar-toggler:focus {
    box-shadow: none;
}


/*Large devices (desktops, 992px and up)*/
@media (max-width: 991px) { 

    .navbar > .container {
        padding-top: 1.5rem;
    }

    .navbar .btn-group-container {
        display: block !important;
        margin-left: inherit;
        margin-block: 2.75rem;
        text-align: center;
    }

    .navbar .contancts-data {
        position: inherit;

    }

    .section-services .container:nth-child(3) > .row:last-child div[class^="col"],
    .section-services > .container:nth-child(1) > .row div[class^="col"] {
        padding: 0 !important;
    }
}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) { 

    footer {
        height: auto;
        padding-top: 10rem;
        -webkit-mask: url('./images/footer-polygon-sm.svg');
        -webkit-mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
    }

    footer .row div[class^="col"] div {
        margin-left: inherit !important;
        margin-right: inherit !important;
    }

    footer .copyright {
        margin-top: 7.9rem;
    }
}